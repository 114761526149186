.loadingbar-sec {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

// .loading-sec {
//     width: 100%;
//     position: relative;
// }
.loading-sec {
  width: 100%;

  img {
    // max-height: 99vh;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .btn-sec {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .startgame-btn {
      display: block;
      // width: 150px; 
      height: auto;
      margin-top: 350px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }


}


.start-screen-sec {
  position: relative;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px auto;

  .game-logo {
    max-width: 50%;
    margin: 0px auto;
    display: block;
    margin-bottom: 60px;
  }

  .gamestart-sec {
    position: relative;
    margin-bottom: 30px;
    display: none;

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 2.5vw;
    }
  }
}

.game-rules-main-sec {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .game-rules-sec {
    max-width: 90%;
    margin: 0px auto;
    position: relative;

    .game-rules-info {
      position: absolute;
      top: 28%;
      left: 8%;
      width: 80%;
      font-size: 30px;
      line-height: 1.2;
      color: #fff;
      text-align: center;

      ul {
        list-style-type: none;

        li {
          font-size: 0.9vw;
          margin-bottom: 10px;
          text-align: left;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -20px;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            background: #ffa500;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    font-size: 40px;
    font-family: "ItalianPlateNo2Expanded-Medium";
  }
}

.circle {
  position: relative;
  width: 200px;
  height: 200px;
}

.circle svg {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);

}

.circle svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 10;
  transform: translate(5px, 5px);
  stroke: #ccc;

}

.circle svg circle:nth-child(2) {
  stroke: #ffa500;
  stroke-linecap: round;
  stroke-dasharray: 490px;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
}

.timer-text {
  position: absolute;
  top: 40%;
  left: 55%;
  transform: translate(-55%, -40%);
  font-size: 90px;
  font-weight: bold;
  color: #ffa500;
}

.exercise-slider {
  max-width: 90%;
  margin: 0px auto;
  width: 100%;

  .exercise-slide {
    text-align: center;
    padding: 10px 0;
    height: 100%;
    min-height: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    position: relative;
    width: 100%;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: #2c3df4;
      border-radius: 20px;
      transform: scale(1);
      transition: 0.3s;
    }

    img {
      max-height: 4vw;
      display: block;
      margin: 0px auto;
      max-width: 4vw;
      transition: 0.3s;
      z-index: 1;
      transform: scale(1);
      position: absolute;
      transition: 0.3s;
    }
  }

  .slick-slide {
    height: 100%;
    transform: scale(1);
    transition: 0.3s;
  }

  .slick-list {
    padding: 50px 0px;
  }

  .slick-slide.slick-active.slick-current+div {
    z-index: 999;
    position: relative;

    .exercise-slide {
      &::after {
        background: #ffa500;
        transition: 0.3s;
        border-radius: 30px;
        transform: scaleX(1.25) scaleY(1.5);
        transition: 0.3s;
      }

      img {
        filter: brightness(0) invert(1);
        transition: 0.3s;
        transform: scale(1.5);
      }
    }
  }
}

.video-sec {
  max-width: 550px;
  margin: 0px auto;
  position: relative;
  margin-bottom: 20px;

  img {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 150%;
    max-width: 100%;
  }

  video {
    position: relative;
    z-index: 1;
    max-width: 100%;
  }
}

.points-sec {
  text-align: center;
  position: relative;

  img {
    max-width: 5vw;
  }

  .score-count {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1vw;
    color: #fff;
  }
}

.footerimg {
  width: 100%;
  align-self: flex-end;
}

.result-sec {
  padding-top: 50px;

  .result-bg-sec {
    max-width: 80%;
    margin: 0px auto;
    position: relative;

    h3 {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 5vw;
      color: #fff;
      width: 100%;
      text-align: center;
    }
  }

}

.result-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;

  button {
    max-width: 200px;
    padding: 0;
    margin: 0px 20px;
    background: transparent;
    cursor: pointer;
    border: none;

    img {
      width: 100%;
    }
  }
}

.reward-sec {
  max-width: 70%;
  margin: 0px auto;
}

.my-rewards-sec {
  >div {
    margin-bottom: -60px;
    position: relative;
    z-index: 1;
  }
}

.playagaon-sec {
  max-width: 80%;
  position: relative;
  margin: 0px auto;
  margin-bottom: -50px;
  z-index: 99;

  form {
    position: absolute;
    bottom: 4.7%;
    width: 80%;
    left: 10%;

    input {
      background: transparent;
      border: none;
      width: 100%;
      margin-bottom: 1.5vw;
      height: 4.5vw;
      font-size: 2vw;
      padding: 0px 10px;
      text-align: center;
      font-family: "ItalianPlateNo2Expanded-Black";
      color: #000;
    }

    input::placeholder {
      color: #000;
    }

    button {
      width: 100%;
      background: transparent;
      border: none;
      height: 4.5vw;
    }
  }
}

.replay-sec {
  position: relative;
  z-index: 1;

  .reply-bottom-frame {
    position: absolute;
    top: 95%;
  }
}

.leaderboard-sec {
  min-height: 100vh;
  width: 100%;
  background: url('../images/leaderboard-bg.png')no-repeat center center/cover;

  .highest-socre-sec {
    text-align: center;
    position: relative;
    margin: 20px 0px;

    img {
      max-width: 5vw;
    }

    h6 {
      font-size: 1vw;
      margin-bottom: 10px;
      color: #fff;
      font-family: 'ItalianPlateNo2Expanded-Black';
    }

    .highest-score {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-family: 'ItalianPlateNo2Expanded-Black';
      font-size: 1vw;

      span {
        width: 100%;
        display: block;
        margin-bottom: -3px;
        font-size: 1.5vw;
        line-height: 1;
      }
    }
  }

  .ranking-list-sec {
    background: url('../images/ranking-bg.png')no-repeat center/cover;
    max-width: 50%;
    margin: 0px auto;
    min-height: 27vw;
    position: relative;

    .ranking-list {
      position: absolute;
      top: 18%;
      left: 10%;
      width: 100%;

      li {
        font-size: 1vw;
        color: #fff;
        font-family: 'ItalianPlateNo2Expanded-Black';
        margin-bottom: 0.5vw;

        .sr-no {
          width: 2vw;
          display: inline-block;
          border: 1px solid #fff;
          padding: 5px;
          text-align: center;
        }

        .player-name {
          width: 10vw;
          display: inline-block;
          border: 1px solid #fff;
          padding: 5px;
          text-align: center;
        }

        .score {
          text-align: center;
          width: 2vw;
          display: inline-block;
        }
      }
    }

    .play-again-btn {
      position: absolute;
      top: 103%;
      width: 100%;
      padding: 12px 40px;
      background: #ffa500;
      border-radius: 15px;
      border: none;
      font-family: 'ItalianPlateNo2Expanded-Black';
      font-size: 20px;
      cursor: pointer;
      min-height: 3vh;
    }
  }

  .highestscore-name {
    margin-bottom: 20px;
    text-align: center;

    span {
      background: #ffa500;
      color: #000;
      font-size: 16px;
      padding: 6px 50px;
      min-width: 150px;
      border-radius: 10px;
      font-family: 'ItalianPlateNo2Expanded-Black';
    }
  }
}

.gameplay {
  max-height: 0;
  overflow: hidden;
}

.gameplay.activegameplay {
  max-height: 100%;
}

.gameplay-header {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  align-items: center;

  .middle-header {
    text-align: center;
    margin-top: 10px;

    h3 {
      font-size: 1vw;

      span {
        color: #ffa500;
        display: block;
      }
    }
  }
}

#label-container {
  display: none;
}

.canvas-parent {
  // canvas { padding-top: 100%;}
}

// .roundtimer-sec {
//   svg {
//     width: 4vw;
//     height: 4vw;
//   }
// }
.canvas-main-sec {
  position: relative;

  .canvas-parent {
    opacity: 0;
  }

  .current-pose-sec {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);

    img {

      min-height: 1100px;
      max-height: 1100px;
      transition: 0.3s;
      object-fit: cover;
    }
  }

  .points-sec {
    position: absolute;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%) scale(1.25);
  }

  .gameplaybottombar {
    position: absolute;
    bottom: 30%;
    width: 100%;
  }
}


@media (min-width : 2100px) {
  .container {
    max-width: 100%;
  }

  .game-rules-main-sec .game-rules-sec .game-rules-info ul li {
    font-size: 2.3vw;
  }

  .game-rules-main-sec .game-rules-sec .game-rules-info ul li::before {
    left: -40px;
  }

  .exercise-slider .exercise-slide img {
    max-width: 6vw;
    max-height: 6vw;
  }

  .exercise-slider .exercise-slide {
    min-height: 10vw;
  }

  .exercise-slider {
    margin-bottom: 50px;
  }

  .points-sec img {
    max-width: 15vw;
  }

  .points-sec .score-count {
    font-size: 5vw;
    top: -3%;
  }

  .gameplay-header .middle-header h3 {
    font-size: 4vw;
  }

  .roundtimer-sec {
    // width: 300px;
    margin-bottom: 100px;
    transform: scale(3);
  }

  .playagaon-sec form input {
    height: 11vw;
    margin-bottom: 4vw;
    font-size: 7vw;
  }

  .playagaon-sec form button {
    height: 11vw;
  }

  .playagaon-sec form {
    bottom: 8%;
  }

  .replay-sec {
    width: 100%;
  }

  .result-bg-sec {
    text-align: center;
  }

  .result-sec .result-bg-sec h3 {
    font-size: 10vw;
    top: 40%;
  }

  .result-btns {
    margin-top: 65px;
    position: relative;
    z-index: 1;
  }

  .result-btns button {
    max-width: 40vw;
  }

  .leaderboard-sec .ranking-list-sec {
    min-height: 80vw;
  }

  .leaderboard-sec .highest-socre-sec h6 {
    font-size: 4vw;
    margin-bottom: 45px;
  }

  .leaderboard-sec .highest-socre-sec img {
    max-width: 15vw;
  }

  .leaderboard-sec .highest-socre-sec .highest-score {
    font-size: 3vw;
    top: 45%;
  }

  .leaderboard-sec .highest-socre-sec .highest-score span {
    font-size: 5vw;
  }

  .leaderboard-sec .highestscore-name span {
    font-size: 3vw;

  }

  .leaderboard-sec .highestscore-name {
    margin-bottom: 40px;
  }

  .leaderboard-sec .ranking-list-sec .play-again-btn {
    font-size: 4vw;
  }

  .leaderboard-sec .ranking-list-sec .ranking-list li {
    font-size: 3vw;
    margin-bottom: 1vw;
  }

  .leaderboard-sec .ranking-list-sec .ranking-list li .sr-no {
    width: 6vw;
  }

  .leaderboard-sec .ranking-list-sec .ranking-list li .player-name {
    width: 28vw;
  }

  .leaderboard-sec .ranking-list-sec .ranking-list li .score {
    width: 6vw;
  }

  .loadingbar-sec {
    transform: scale(4) translateX(-10%) translateY(40%);
  }

  .gameplay-header {
    flex-direction: column;
  }

  .countdown-timer h3 {
    font-size: 6vw;
  }

  .countdown-timer .circle {
    transform: scale(3);
    transform-origin: top;
  }
}