

@font-face {
    font-family: 'ItalianPlateNo2Expanded-Demibold';
    src: url('../fonts/ItalianPlateNo2Expanded-Demibold.eot');
    src: url('../fonts/ItalianPlateNo2Expanded-Demibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ItalianPlateNo2Expanded-Demibold.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Demibold.woff') format('woff'),
        url('../fonts/ItalianPlateNo2Expanded-Demibold.ttf') format('truetype'),
        url('../fonts/ItalianPlateNo2Expanded-Demibold.svg#ItalianPlateNo2Expanded-Demibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ItalianPlateNo2Expanded-Black';
    src: url('../fonts/ItalianPlateNo2Expanded-Black.eot');
    src: url('../fonts/ItalianPlateNo2Expanded-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ItalianPlateNo2Expanded-Black.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Black.woff') format('woff'),
        url('../fonts/ItalianPlateNo2Expanded-Black.ttf') format('truetype'),
        url('../fonts/ItalianPlateNo2Expanded-Black.svg#ItalianPlateNo2Expanded-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ItalianPlateNo2Expanded-Extrabold';
    src: url('../fonts/ItalianPlateNo2Expanded-Extrabold.eot');
    src: url('../fonts/ItalianPlateNo2Expanded-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ItalianPlateNo2Expanded-Extrabold.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Extrabold.woff') format('woff'),
        url('../fonts/ItalianPlateNo2Expanded-Extrabold.ttf') format('truetype'),
        url('../fonts/ItalianPlateNo2Expanded-Extrabold.svg#ItalianPlateNo2Expanded-Extrabold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ItalianPlateNo2Expanded-Medium';
    src: url('../fonts/ItalianPlateNo2Expanded-Medium.eot');
    src: url('../fonts/ItalianPlateNo2Expanded-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ItalianPlateNo2Expanded-Medium.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Medium.woff') format('woff'),
        url('../fonts/ItalianPlateNo2Expanded-Medium.ttf') format('truetype'),
        url('../fonts/ItalianPlateNo2Expanded-Medium.svg#ItalianPlateNo2Expanded-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



*                           { box-sizing: border-box;}
body						{   }
.clear						{ clear:both;}
img							{ border: 0px; max-width: 100%;}
ul,ol						{ list-style: none; margin: 0; padding: 0}
a							{ text-decoration: none; outline: none; display: inline-block; }
a:focus, a:active,a:visited	{ outline: none; border: 0px;}
a:hover                     { text-decoration: none;}
select:focus 				{ outline: none; }
input:focus 				{  outline: none; }
textarea:focus 				{  outline: none; }
textarea 					{ resize: none; }

.container {
    max-width: 35%;
    max-height: 100vh;
    // height: 100vh;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-y: auto;
    flex-wrap: wrap;
    min-height: 99vh;
}
.slick-initialized .slick-slide {
    height: 100%;
}
.error-msg {
    position: fixed;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    margin: 0;
    font-family: 'ItalianPlateNo2Expanded-Black';
    background: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}