/* Loader.scss */
$loader-width: 300px;
$loader-height: 40px;
$loader-radius: 20px;
$loader-bg-color: transparent;
$loader-bar-color: #FFB74D; // orange
$loader-text-color: white;
$loader-font-family: Arial, sans-serif;
$loader-font-size: 16px;
$loader-font-weight: bold;

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: relative;
  width: $loader-width;
  height: $loader-height;
  background: $loader-bg-color;
  border: 2px solid $loader-bar-color; // border to visually define the loader
  border-radius: $loader-radius;
  overflow: hidden;

  .loader-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $loader-bar-color;
    animation: loading 3s forwards;
  }

  .loader-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $loader-font-family;
    font-size: $loader-font-size;
    font-weight: $loader-font-weight;
    color: $loader-text-color;
    z-index: 1;
  }
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
